import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/pengaturan/approval',
        redirect: '/pengaturan/approval/produk',
        component: () => import('@/layout/ApprovalSettingsLayout.vue'),
        children: [
            {
                path: ':approvalType',
                name: 'ApprovalSettingsView',
                component: () => import('@/views/ApprovalSettingsView.vue'),
            },
        ],
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
